import React from "react";

function BrainCancer() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="85"
            fill="none"
            viewBox="0 0 77 70"
        >
            <g fill="#2380C3" clipPath="url(#clip0_1_49)">
                <path d="M62.563 40.304h-3.209V37.08h3.209c2.978 0 5.834-1.19 7.94-3.306a11.314 11.314 0 003.289-7.98c0-2.992-1.183-5.863-3.29-7.98a11.201 11.201 0 00-7.94-3.305v-3.224c3.83 0 7.502 1.529 10.21 4.25A14.546 14.546 0 0177 25.795c0 3.848-1.521 7.538-4.229 10.26a14.401 14.401 0 01-10.209 4.249zM24.255 13.171l-3.208-.548a15.252 15.252 0 015.22-9.084A15.113 15.113 0 0136.094 0c2.648.01 5.248.708 7.547 2.028 2.3 1.319 4.22 3.214 5.575 5.5l-2.76 1.613a12.053 12.053 0 00-5.344-4.845 11.977 11.977 0 00-7.139-.912 12.012 12.012 0 00-6.38 3.347 12.111 12.111 0 00-3.338 6.408v.032z"></path>
                <path d="M48.125 51.59H16.042a16.002 16.002 0 01-11.344-4.723A16.162 16.162 0 010 35.467 16.269 16.269 0 013.524 25.43a16.128 16.128 0 018.956-5.681l.706 3.224a12.814 12.814 0 00-6.142 3.385 12.913 12.913 0 00-3.459 6.12 12.959 12.959 0 00.255 7.036 12.902 12.902 0 003.892 5.852 12.699 12.699 0 008.31 2.999h32.083a9.6 9.6 0 006.806-2.833 9.698 9.698 0 002.819-6.84 9.698 9.698 0 00-2.82-6.84 9.6 9.6 0 00-6.805-2.833v-3.224c3.404 0 6.668 1.358 9.075 3.777a12.93 12.93 0 013.758 9.12 12.93 12.93 0 01-3.758 9.12 12.801 12.801 0 01-9.075 3.777z"></path>
                <path d="M3.208 26.423V24.99a20.235 20.235 0 015.883-14.24 20.034 20.034 0 0114.17-5.913c.847-.004 1.694.05 2.534.162l-.417 3.224c-.702-.095-1.41-.15-2.118-.161a16.818 16.818 0 00-11.905 4.963 16.987 16.987 0 00-4.938 11.964v1.21l-3.209.225zM25.667 61.262a12.797 12.797 0 01-8.433-3.185 12.916 12.916 0 01-4.272-7.971l3.208-.42a9.686 9.686 0 003.234 5.906 9.597 9.597 0 006.287 2.348 9.597 9.597 0 006.286-2.348 9.686 9.686 0 003.235-5.905l3.208.451a12.915 12.915 0 01-4.3 7.967 12.796 12.796 0 01-8.453 3.157z"></path>
                <path d="M36.331 49.315l-8.889 19.35 2.913 1.352 8.89-19.35-2.914-1.352zM28.875 51.59v-3.225a7.993 7.993 0 004.626-1.479 8.057 8.057 0 002.93-3.89l3.016 1.064a11.28 11.28 0 01-4.097 5.452 11.192 11.192 0 01-6.475 2.077zM59.226 33.855l-2.76-1.612a9.71 9.71 0 00.354-8.98l2.887-1.386a12.577 12.577 0 011.251 5.53 12.736 12.736 0 01-1.732 6.448zM5.133 46.914a10.723 10.723 0 01-.32-2.58 10.222 10.222 0 012.084-6.267 10.136 10.136 0 015.471-3.663l.93 3.095a6.927 6.927 0 00-3.823 2.494 6.987 6.987 0 00-1.454 4.341c.002.598.078 1.194.224 1.774l-3.112.806zM66.605 14.687c-.679-2-1.784-3.826-3.238-5.352a14.434 14.434 0 00-5.18-3.482 14.373 14.373 0 00-12.147.773l-1.605-2.837a17.58 17.58 0 0114.866-.944 17.64 17.64 0 016.336 4.269 17.75 17.75 0 013.952 6.557l-2.984 1.016zM30.48 40.304c-2.539 0-5.02-.756-7.13-2.174a12.883 12.883 0 01-4.727-5.788 12.958 12.958 0 01-.73-7.451 12.918 12.918 0 013.512-6.604 12.814 12.814 0 016.57-3.53c2.49-.497 5.07-.242 7.415.734 2.345.976 4.35 2.63 5.76 4.75a12.943 12.943 0 012.163 7.166 12.93 12.93 0 01-3.76 9.12 12.801 12.801 0 01-9.074 3.777zm0-22.57a9.59 9.59 0 00-5.348 1.63 9.663 9.663 0 00-3.545 4.341 9.72 9.72 0 00-.548 5.589 9.688 9.688 0 002.634 4.953 9.61 9.61 0 004.928 2.647 9.581 9.581 0 005.562-.55 9.636 9.636 0 004.32-3.563 9.708 9.708 0 00-1.198-12.214 9.6 9.6 0 00-6.806-2.833z"></path>
                <path d="M32.182 15.35L18.479 29.122l2.269 2.28 13.702-13.77-2.268-2.28zM37.29 18.287l-15.88 15.96 2.268 2.279 15.88-15.96-2.268-2.28zM40.204 23.411L26.502 37.182l2.268 2.28 13.703-13.77-2.269-2.28z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_49">
                    <path fill="#fff" d="M0 0H77V70H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default BrainCancer;
