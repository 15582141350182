import React, { ReactElement } from "react";

import styles from "./styles.module.scss";
import BrainCancer from "../icons/brainCancer";
import HeartAttack from "../icons/heartAttack";
import LungDisease from "../icons/lungDisease";
import KidneyCancer from "../icons/kidneyCancer";

export default function BottomSection(): ReactElement {
    return (
        <section className={` mt-20 mb-52 ${styles["contentStyle"]}`}>
            <h2>Injuries Named in CPAP Lawsuits</h2>
            <span></span>
            <p>
                Deteriorating noise reduction foam in Philips' CPAP machines has
                been linked to various health issues, prompting legal action
                alleging the devices contribute to cancer and damage to organs.
                Philips acknowledged receiving user complaints about symptoms
                such as headaches, difficulty breathing, chest discomfort,
                coughing, and sinus infections. Additionally, there's concern
                about more severe hazards, potentially leading to
                life-threatening conditions or lasting harm.
            </p>
            <p className="font-bold mt-2 capitalize">
                Lawyers Are Accepting Philips CPAP Lawsuit Claims From People
                Who Suffered the Following Injuries After Using One of the
                Recalled Devices:
            </p>
            <div className={` ${styles["iconsStyle"]} `}>
                <div>
                    <span>
                        <BrainCancer />
                    </span>

                    <h3>Brain Cancer</h3>
                </div>
                <div>
                    <span>
                        <HeartAttack />
                    </span>

                    <h3>Heart Attack</h3>
                </div>
                <div>
                    <span>
                        <LungDisease />
                    </span>

                    <h3>Lung Disease</h3>
                </div>
                <div>
                    <span>
                        <KidneyCancer />
                    </span>

                    <h3>Kidney Cancer</h3>
                </div>
            </div>
        </section>
    );
}
