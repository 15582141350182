import React from "react";

function KidneyCancer() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="85"
            fill="none"
            viewBox="0 0 85 85"
        >
            <mask
                id="mask0_1_90"
                style={{ maskType: "alpha" }}
                width="85"
                height="85"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#2380C3" d="M0 0H85V85H0z"></path>
            </mask>
            <g fill="#2380C3" mask="url(#mask0_1_90)">
                <path d="M64.776 63.829c-1.472 0-2.85-.33-4.062-.96-3.73-1.935-5.994-6.883-2.88-13.667-.677-1.503-1.725-5.19 1.36-9.721-.396-1.067-.933-2.17-1.497-3.328-1.403-2.876-2.85-5.851-2.476-8.998.393-3.282 1.984-6.212 4.368-8.04 2.29-1.757 5.215-2.433 8.45-1.974 4.302.622 8.162 3.245 10.593 7.198 3.191 5.189 7.547 15.753.853 28.185-4.237 7.87-9.959 11.305-14.709 11.305zm-6.882-36.356c-.28 2.361.99 4.975 2.222 7.502.656 1.347 1.335 2.74 1.794 4.12l.059.177c.14.419.065.88-.2 1.232-3.398 4.533-1.39 7.803-1.303 7.94.253.398.288.913.076 1.337-2.733 5.467-1.26 9.313 1.411 10.701 3.96 2.055 10.438-.461 15.162-9.232 5.751-10.682 2.754-19.764-.774-25.502-2.012-3.269-5.178-5.435-8.685-5.942-2.544-.36-4.704.122-6.43 1.445-1.81 1.389-3.026 3.657-3.332 6.222z"></path>
                <path d="M59.447 43.06h-.02c-10.818-.154-13.127-5.727-13.54-9.036-.119-.963.18-1.93.82-2.657a3.384 3.384 0 012.535-1.142c1.62 0 2.984 1.129 3.328 2.745.46 2.152 3.06 3.337 7.324 3.337a1.345 1.345 0 110 2.691c-7.646 0-9.52-3.425-9.956-5.466-.066-.308-.303-.616-.684-.616a.688.688 0 00-.697.775c.372 2.98 2.567 6.559 10.91 6.678a1.346 1.346 0 01-.02 2.692z"></path>
                <path d="M47.826 68.132a3.381 3.381 0 01-3.377-3.378V49.18c0-5.07 1.04-8.464 3.09-10.09 1.63-1.29 3.247-.957 3.427-.915a1.347 1.347 0 01.72 2.181 4.505 4.505 0 011.608-.769 1.344 1.344 0 11.661 2.608c-.645.164-2.752 1.25-2.752 8.339v14.222a3.381 3.381 0 01-3.377 3.377zm2.433-27.338c-.211 0-.642.057-1.11.456-.75.637-2.008 2.514-2.008 7.928v15.576a.687.687 0 001.372 0V50.532c0-5.654 1.284-8.495 2.804-9.876a1.353 1.353 0 01-.932.146.82.82 0 00-.126-.008zM21.225 63.829c-4.751 0-10.471-3.434-14.71-11.305-6.694-12.432-2.339-22.997.854-28.185 2.43-3.953 6.291-6.576 10.593-7.197 3.238-.464 6.16.216 8.45 1.972 2.384 1.829 3.976 4.76 4.368 8.04.376 3.147-1.074 6.123-2.475 8.999-.564 1.159-1.1 2.26-1.497 3.327 3.085 4.53 2.035 8.219 1.359 9.722 3.116 6.784.85 11.732-2.879 13.667-1.213.63-2.591.96-4.063.96zm-1.293-44.14c-.511 0-1.04.04-1.586.118-3.509.506-6.674 2.671-8.685 5.941-3.529 5.737-6.528 14.818-.776 25.502 4.726 8.775 11.208 11.285 15.163 9.232 2.672-1.388 4.145-5.234 1.41-10.702a1.346 1.346 0 01.085-1.348c.08-.125 2.087-3.397-1.31-7.927a1.345 1.345 0 01-.201-1.233l.058-.177c.46-1.38 1.138-2.773 1.795-4.118 1.231-2.529 2.503-5.143 2.221-7.502v-.001c-.306-2.566-1.52-4.834-3.333-6.224-1.354-1.038-2.977-1.561-4.84-1.561z"></path>
                <path d="M26.553 43.06a1.346 1.346 0 01-.018-2.69c8.342-.12 10.536-3.699 10.909-6.68a.685.685 0 00-.685-.774c-.393 0-.63.309-.695.615-.436 2.042-2.31 5.467-9.957 5.467a1.345 1.345 0 110-2.691c4.264 0 6.866-1.186 7.325-3.338.345-1.615 1.709-2.744 3.315-2.744.982 0 1.906.416 2.547 1.143.64.726.94 1.694.82 2.656-.413 3.309-2.721 8.882-13.541 9.037h-.02z"></path>
                <path d="M38.175 68.132a3.381 3.381 0 01-3.378-3.378V50.533c0-7.088-2.105-8.175-2.751-8.34a1.345 1.345 0 01-.973-1.635c.183-.72.918-1.16 1.636-.972.586.149 1.2.428 1.788.91a1.314 1.314 0 01-.45-.755c-.142-.71.28-1.402.987-1.567.182-.044 1.8-.372 3.428.916 2.05 1.625 3.09 5.02 3.09 10.09v15.574a3.38 3.38 0 01-3.377 3.377zm-3.663-27.623c1.593 1.32 2.976 4.16 2.976 10.024v14.222a.687.687 0 001.373 0V49.18c0-5.416-1.258-7.292-2.008-7.93-.626-.53-1.183-.46-1.243-.447-.399.069-.8-.052-1.098-.294zM13.397 54.676a1.33 1.33 0 01-.46-.082l-6.099-2.22a1.344 1.344 0 11.921-2.53l5.388 1.963 6.138-4.911a1.346 1.346 0 011.68 2.102l-6.727 5.383c-.242.194-.54.295-.84.295z"></path>
                <path d="M20.127 58.712c-.494 0-.97-.272-1.205-.743l-1.07-2.138-4.781-1.196a1.346 1.346 0 11.653-2.61l5.382 1.346c.381.094.701.351.877.702l1.346 2.691a1.346 1.346 0 01-1.202 1.948zM29.492 31.064c-.148 0-.3-.025-.447-.076l-9.368-3.301a1.345 1.345 0 11.895-2.539l9.368 3.301a1.345 1.345 0 01-.448 2.615z"></path>
                <path d="M18.78 38.529a1.345 1.345 0 01-1.346-1.346v-4.037c0-.397.175-.773.479-1.029l6.03-5.077a1.345 1.345 0 111.733 2.058l-5.55 4.674v3.411c0 .744-.603 1.346-1.346 1.346z"></path>
            </g>
        </svg>
    );
}

export default KidneyCancer;
